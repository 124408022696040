<template>
  <div>
    <b-container class="mt-5 mb-5">
      <b-button to="/manage/organisation/team/create" variant="primary" class="float-right">
        Teamlid toevoegen
      </b-button>
      <h1 class="fs-4 font-weight-bold mb-4">Team</h1>

      <template v-if="!team.length">
        <b-card no-body>
          <b-card-body>
              <div class="text-center text-muted mt-4 mb-4">
                  <font-awesome-icon icon="users" class="mb-2" size="2x" />
                  <div class="text-dark fs-5">Geen teamleden gevonden.</div>
                  <div>Voeg een teamlid toe voor uw organisatie.</div>
              </div>
          </b-card-body>
        </b-card>
      </template>

      <template v-else>
        <b-list-group class="shadow">
          <b-list-group-item v-for="(member, key) in team" :key="key" :to="`/manage/organisation/team/edit/` + member.id">
              <div class="float-left">
                  <font-awesome-icon class="intro-icon mt-1" icon="fa-solid fa-user" size="2x" />
              </div>
              <div class="icon-text">
                <font-awesome-icon class="arrow-icon float-right" icon="fa-solid fa-chevron-right" />
                <b-row>
                  <b-col md="6">
                    <div>{{ member.firstname }} {{ member.lastname }}</div>
                    <div class="text-muted">{{ member.username }}</div>
                  </b-col>
                  <b-col md="6">
                    <div class="text-mute mr-2">Toegevoegd op: {{ member.created_at|formatDate }}</div>
                    <div class="text-mute mr-2">Rol: {{ member.role }}</div>
                  </b-col>
                </b-row>
              </div>
          </b-list-group-item>
        </b-list-group>
      </template>

    </b-container>
  </div>
</template>

<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'

  export default {
      metaInfo: {
          title: 'Team',
      },
      components: {
      },
      data() {
          return {
            team: [],
          }
      },
      computed: {},
      methods: {
        ...mapActions(useShopStore, ['setLoading']),
        getTeam: function() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/teams")
            .then( response => {
              this.team = response.data;
              this.setLoading(false);
          }).catch(response => { console.log(response); });
        }
      },
      created() {
        this.getTeam();
      }
  }
</script>


<style scoped>
.intro-icon{
  background-color:#CA355B;
  font-size: 25px;
  padding:8px;
  color:#fff;
  border-radius:10px;
  height:25px;
  width:25px;
}
.icon-text{
  padding-left:60px;
}
.arrow-icon{
  float:right;
  top:50%;
  margin-top:15px;
  padding-left:15px;
  color:#999;
}
</style>